<div class="details-form d-flex flex-wrap flex-row grid-gap-3">
    <div class="flex-1 flex-basis-min">
        <app-copy-credentials [canEdit]="canEdit" [user]="cluster.api_user" [password]="cluster.api_password"></app-copy-credentials>
        <div class="form-group">
            <h5 translate>{{ "ALTERNATIVE_CLUSTER" | translate }}</h5>
            <div>
                <zx-cluster
                    *ngIf="alternativeCluster"
                    [model]="alternativeCluster"
                    [showOtherIcons]="true"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-cluster>
                <span *ngIf="!alternativeCluster">-</span>
            </div>
        </div>
        <div class="form-group" *ngIf="cluster.alertingProfile">
            <h5 translate>ALERTING_PROFILE</h5>
            <div>
                <a [routerLink]="['/' + urls.configuration.eventsManagement, cluster.alertingProfile.id]">
                    {{ cluster.alertingProfile.name }}
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="numberOfTotalIps > 0">
            <h5 translate title="{{ 'ELASTIC_IPS' | translate }}">ELASTIC_IPS</h5>
            <div class="outlet-container">
                <span>{{ numberOfRemainingIps }} / {{ numberOfTotalIps }} Available</span>
                <div class="ms-1" *ngIf="!(numberOfRemainingIps > 0)">
                    <fa-icon icon="exclamation-circle" class="status-warning" [ngbTooltip]="infoTooltipRef" triggers="hover click" [closeDelay]="500"></fa-icon>
                    <ng-template #infoTooltipRef>{{ "TOOLTIP.NO_MORE_ELASTIC_IP" | translate }}</ng-template>
                </div>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>SCALING_ACCOUNT</h5>
            <div>
                <span>{{ cluster._frontData.scaling | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="scalingAccount && scalingAccount.name">
            <h5 translate>ACCOUNT_NAME</h5>
            <div>
                <span>{{ scalingAccount.name }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="cluster && cluster.rtmp_input_port > 0">
            <h5 translate>RTMP_SERVER</h5>
            <div>
                <span>{{ "PORT" | translate }}&nbsp;</span>
                <span>{{ cluster.rtmp_input_port }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>AUTHORIZATION_MODE</h5>
            <div>
                <span *ngIf="cluster.auth_mode === 'zen'">{{ "ZEN_MASTER" | translate }}</span>
                <span *ngIf="cluster.auth_mode === 'password'">{{ "GLOBAL_PASSWORD" | translate }}</span>
                <span *ngIf="cluster.auth_mode === 'ffa'">{{ "FREE_FOR_ALL" | translate }}</span>
                <span *ngIf="cluster.auth_mode === 'custom' || !cluster.auth_mode">{{ "MANUAL" | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="cluster && cluster.auth_mode === 'password'">
            <h5 translate>AUTHORIZATION_PASSWORD</h5>
            <div>
                <a href="javascript:void(0)" (click)="authVisible = true" *ngIf="!authVisible">
                    {{ "SHOW_PASSWORD" | translate }}
                </a>
                <span *ngIf="authVisible">{{ cluster.auth_param }}</span>
            </div>
        </div>
        <app-generic-detail
            *ngIf="cluster.dtls"
            header="IGNORE_DTLS_CERTIFICATE_WHEN_NOT_SUPPORTED"
            [content]="cluster.allow_ignore_dtls_cert ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            header="LOG_SCTE_35"
            [content]="cluster.configure_scte_reports ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="cluster.auth_mode === ('zen' || 'ffa')"
            header="PUSH_INPUTS"
            [content]="cluster.allow_unmanaged_inputs ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="cluster.auth_mode === ('zen' || 'ffa')"
            header="PULL_OUTPUTS"
            [content]="cluster.allow_unmanaged_outputs ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            header="TERMINATION_PROTECTION"
            [content]="cluster.termination_protection ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <!-- Advanced -->
        <app-generic-detail
            header="LOAD_BALANCE_PULL_TARGETS"
            [content]="cluster.load_balance === 1 ? ('UNIFORM_DISTRIBUTION' | translate) : ('MINIMIZE_THE_NUMBER_OF_BROADCASTERS' | translate)"
        ></app-generic-detail>
        <app-generic-detail header="HTTP_STREAMING_PORT" [content]="cluster.http_streaming_port ?? ''"></app-generic-detail>
        <app-generic-detail *ngIf="cluster.scaling === 'Azure'" header="PUBLIC_IP_PREFIX" [content]="cluster.eips ?? ''"></app-generic-detail>
        <app-generic-detail
            *ngIf="scalingAccount && scalingAccount.type === 'aws'"
            header="ELASTIC_IP_ALLOCATION_IDs"
            [content]="cluster.eips ?? ''"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="scalingAccount && scalingAccount.type === 'aws'"
            header="REQUIRE_ELASTIC_IP"
            [content]="cluster.require_eip ? ('ENABLED' | translate) : ''"
        ></app-generic-detail>
        <app-generic-detail header="INPUT_BANDWIDTH_LIMIT" unit="mbps" [content]="cluster.bx_input_bw_limit ?? ''"></app-generic-detail>
        <app-generic-detail header="OUTPUT_BANDWIDTH_LIMIT" unit="mbps" [content]="cluster.bx_output_bw_limit ?? ''"></app-generic-detail>
        <app-generic-detail
            *ngIf="scalingAccount && (scalingAccount.type === 'aws' || scalingAccount.type === 'azure' || scalingAccount.type === 'gcp')"
            header="REAL_TIME_PRIORITY"
            [content]="cluster.realtime_bx_priority ? ('ENABLED' | translate) : ''"
        ></app-generic-detail>

        <app-copy-credentials
            *ngIf="cluster.inputs_billing_code || cluster.inputs_billing_password"
            [user]="cluster.inputs_billing_code"
            [password]="cluster.inputs_billing_password"
            [showPasswordStrength]="false"
            [label]="'INPUTS_BILLING_CREDENTIALS' | translate"
            [userLabel]="'COPY_CODE' | translate"
            [canEdit]="canEdit"
        ></app-copy-credentials>
        <app-copy-credentials
            *ngIf="cluster.outputs_billing_code || cluster.outputs_billing_password"
            [user]="cluster.outputs_billing_code"
            [password]="cluster.outputs_billing_password"
            [showPasswordStrength]="false"
            [label]="'OUTPUTS_BILLING_CREDENTIALS' | translate"
            [userLabel]="'COPY_CODE' | translate"
            [canEdit]="canEdit"
        ></app-copy-credentials>

        <div *ngIf="canEdit && cluster.auth_mode === 'zen'">
            <app-generic-detail header="EXTERNAL_AUTH_URL" [content]="cluster.external_auth_url ?? ''"></app-generic-detail>
            <app-generic-detail header="EXTERNAL_AUTH_TYPE" [content]="cluster.external_auth_type ?? ''"></app-generic-detail>
            <app-generic-detail header="EXTERNAL_AUTH_CREDS" [content]="cluster.external_auth_credentials ?? ''"></app-generic-detail>
        </div>

        <app-generic-detail
            *ngIf="scalingAccount && scalingAccount.type === 'aws'"
            header="ENHANCED_MONITORING"
            [content]="cluster.detailed_monitoring ? ('ENABLED' | translate) : ''"
        ></app-generic-detail>
    </div>

    <div class="flex-1 flex-basis-min" *ngIf="cluster && cluster._frontData.is_auto_scaling">
        <div class="form-group">
            <h5 translate>REGION</h5>
            <div>
                <span>{{ cluster._frontData.prettyRegionName || cluster.region }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="cluster._frontData.is_gcp">
            <h5 translate>ZONES</h5>
            <div>
                <span>{{ cluster._frontData.prettyGCPZones }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="!cluster._frontData.is_azure && !cluster._frontData.is_gcp && !cluster._frontData.is_linode">
            <h5 translate>KEY_PAIR</h5>
            <div>
                <span>{{ cluster.key_pair }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="(cluster._frontData.is_azure || cluster._frontData.is_linode) && cluster.accessKey">
            <h5 translate>SSH_KEY</h5>
            <div>
                <span *ngIf="cluster.accessKey.readOnly || (!cluster.accessKey.readOnly && !cluster.accessKey.hasRsaPrivate)">{{
                    cluster.accessKey.name
                }}</span>
                <a *ngIf="!cluster.accessKey.readOnly && cluster.accessKey?.hasRsaPrivate" href="/api/access_keys/{{ cluster.accessKey.id }}/download">
                    <fa-icon icon="download" size="sm"></fa-icon>
                    {{ cluster.accessKey.name }}
                </a>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>ACTIVATION_KEY</h5>
            <div>
                <span>{{ cluster.activation_key }}</span
                ><span class="ms-1 text-primary cursor-pointer"
                    ><fa-icon icon="clipboard" size="sm" (click)="copyPassword(cluster.activation_key)"></fa-icon
                ></span>
            </div>
        </div>
        <app-generic-detail
            *ngIf="cluster._frontData?.is_auto_scaling && !cluster._frontData?.is_gcp"
            header="VPC"
            [content]="cluster._frontData.vpcName ?? ''"
        ></app-generic-detail>

        <app-generic-detail
            *ngIf="cluster._frontData?.is_auto_scaling"
            header="SUBNET"
            [content]="
                cluster._frontData.prettyGCPSubnet || cluster._frontData.subnetName ? cluster._frontData.prettyGCPSubnet || cluster._frontData.subnetName : ''
            "
        ></app-generic-detail>
        <div class="form-group" *ngIf="!cluster._frontData.is_gcp">
            <h5 translate>SECURITY_GROUP</h5>
            <div>
                <span>{{ cluster._frontData.securityGroupName }}</span>
            </div>
        </div>
        <app-generic-detail
            *ngIf="scalingAccount && scalingAccount.type === 'linode'"
            header="IMAGE"
            [content]="cluster.linode_image_id ?? ''"
        ></app-generic-detail>
        <app-generic-detail
            [header]="scalingAccount && scalingAccount.type === 'linode' ? ('INSTANCE_SIZE' | translate) : ('INSTANCE_TYPE' | translate)"
            [content]="cluster.instance_type ?? ''"
        ></app-generic-detail>
        <app-generic-detail header="ROOT_DEVICE_SIZE" unit="GiB" [content]="cluster.root_device_size ?? ''"></app-generic-detail>
        <div class="form-group" *ngIf="cluster.gcp_account_id != null">
            <h5 translate>GPU_TYPE</h5>
            <div>
                <span>{{ cluster.gcp_gpu_type || ("NONE" | translate) }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>BROADCASTER_VERSION</h5>
            <div>
                <span>{{ cluster.broadcaster_version }}</span>
            </div>
        </div>
        <div *ngIf="scalingAccount && scalingAccount.type === 'aws'">
            <app-generic-detail header="DPDK_SUPPORT" [content]="cluster.dpdk ? ('ENABLED' | translate) : ('DISABLED' | translate)"></app-generic-detail>
            <app-generic-detail header="DPDK_SUBNETS" [content]="cluster.dpdk_subnet ?? ''"></app-generic-detail>
            <app-generic-detail header="DPDK_SECURITY_GROUPS" [content]="cluster.dpdk_security_groups ?? ''"></app-generic-detail>
            <app-generic-detail header="DPDK_ELASTIC_IP_ALLOCATION_IDs" [content]="cluster.dpdk_elastic_ips ?? ''"></app-generic-detail>
        </div>
    </div>
</div>
