import { Component, OnInit, OnDestroy, inject, ViewChild } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, NgForm, NgModel } from "@angular/forms";
import { firstValueFrom, Subscription, map } from "rxjs";
import * as _ from "lodash";

import { TranslateService } from "@ngx-translate/core";
import { FilterPipe } from "../../../pipes/filter.pipe";
import { Constants } from "../../../constants/constants";
import { SharedService } from "../../../services/shared.service";
import { ClustersService } from "../clusters.service";
import {
    Cluster,
    VersionsResponse,
    VirtualMachineSize,
    GCPMachine,
    GCPRegionZone,
    AWSVPCs,
    ZcpBroadcasterVersion
} from "../cluster";
import { UsersService } from "../../account-management/users/users.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { ClipboardService } from "ngx-clipboard";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
import { aws } from "@zixi/shared-utils";
import { CustomImageService } from "../../configuration/custom-image/custom-image.service";
import {
    AWSInstanceDetailsList,
    AWSInstancex86MachineTypes,
    AWSInstanceArmMachineTypes,
    AWSInstanceDeprecatedx86MachineTypes,
    AWSInstanceWavelengthMachineTypes,
    LinodeAccount,
    LinodeImage
} from "@zixi/models";
import { AwsAccount, AzureAccount, CustomImage, GcpAccount } from "src/app/models/shared";
import { LinodeAccountsService } from "../../configuration/linode-accounts/linode-accounts.service";

interface ScalingAccount {
    name: string;
    id: number;
    groupName?: string;
    type?: string;
}

interface ScalingAccountOption {
    name: string;
    id: string;
    groupName?: string;
    type?: string;
}

@Component({
    selector: "app-cluster-form",
    templateUrl: "./cluster-form.component.html",
    providers: [CurrencyPipe]
})
export class ClusterFormComponent implements OnInit, OnDestroy {
    @ViewChild("dpdk_subnets") dpdkSubnetSelectElement: NgModel;

    cluster: Cluster;
    private existingCluster: Cluster;
    private clusterId: number;
    clusterDNSPrefixes: string[];
    clusterNames: string[];
    private action: string;

    submitted = false;
    minLength = 3;

    isEdit = false;

    loading = true;
    init = true;
    saving = false;
    showAdvanced = false;
    private dnsPrefixPristine = true;
    applicationHost: string;
    constants = Constants;
    pwVisible: boolean;
    regionsLoadError: null | string = null;
    hasMixedSubnets = false;
    hasDPDKMixedSubnets = false;
    unmatchedDPDKSubnetsPresent = false;
    isSubnetTypeWavelength = false;
    customError: string | null = null;

    rtmpOn: boolean;

    private clustersSubscription: Subscription;

    //
    dnsPrefix: string;
    loadingScalingAccounts: boolean;
    scalingAccount: ScalingAccount;
    private scalingAccountManual: ScalingAccount = { name: "Manual", id: 0, type: "manual" };
    scalingAccounts: ScalingAccountOption[] = [{ name: "Manual", id: "manual-0", groupName: null }];
    scalingAccountSelector: string;
    //
    private azureRegions = Constants.azureRegions;
    private gcpRegions = Constants.gcpRegions;
    loadingScalingRegions: boolean;
    scalingRegion: string;
    autoscalingRegions: { id: string; name: string }[];
    //
    loadingRegionData: boolean;
    key: string;
    keyList: { id: string; name: string }[] = [];
    private vpcList: AWSVPCs;
    vpcOptions: { id: string; name: string; subnets?: { id: string; name: string }[] }[];
    securityGroupOptions: { id: string | number; name: string }[];
    subnetOptions: { id: string; name: string }[] = [];
    clusterSubnet: string | string[];
    clusterSecurityGroup: number | string | string[];
    dpdkClusterSubnet: string | string[];
    dpdkClusterSecurityGroup: string | string[];
    instanceTypesWithPricing: { id: string; name: string }[];
    broadcasterVersions: ZcpBroadcasterVersion[] = [];
    private pricing: AWSInstanceDetailsList;

    showDeprecatedInstanceTypes = false;

    linodeImages: LinodeImage[] = [];
    linodeVMSizes = Constants.linodeInstanceTypes;
    azureVMSizes: VirtualMachineSize[] = [];
    gcpVMSizes: GCPMachine[] = [];
    zonesOptions: GCPRegionZone[] = [];
    gcpZones: string[] = [];

    private defaultCustomImage: CustomImage = {
        id: 0,
        name: "Use ZEN Master default AMI",
        architecture: "arm",
        nvidia_cuda_version: null,
        ami_id: null,
        region: null
    };

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private cs = inject(ClustersService);
    private fp = inject(FilterPipe);
    private cp = inject(CurrencyPipe);
    private translate = inject(TranslateService);
    private sharedService = inject(SharedService);
    private us = inject(UsersService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private customImageService = inject(CustomImageService);
    private cbs = inject(ClipboardService);
    private linodeService = inject(LinodeAccountsService);

    private nvidiaOptionsLegacy = [{ name: this.translate.instant("NVIDIA_TESLA_K80"), id: "nvidia-tesla-k80" }];
    nvidiaOptions = [
        { name: this.translate.instant("NVIDIA_TESLA_T4"), id: "nvidia-tesla-t4" },
        { name: this.translate.instant("NVIDIA_TESLA_V100"), id: "nvidia-tesla-v100" },
        { name: this.translate.instant("NVIDIA_TESLA_P100"), id: "nvidia-tesla-p100" },
        { name: this.translate.instant("NVIDIA_TESLA_P4"), id: "nvidia-tesla-p4" },
        { name: this.translate.instant("NONE"), id: "none" }
    ];

    authOptions = [
        { name: this.translate.instant("ZEN_MASTER"), id: "zen" },
        { name: this.translate.instant("GLOBAL_PASSWORD"), id: "password" },
        { name: this.translate.instant("FREE_FOR_ALL"), id: "ffa" },
        { name: this.translate.instant("MANUAL"), id: "custom" }
    ];
    //
    private bxVersions: VersionsResponse;
    private awsAccounts: AwsAccount[];
    private azureAccounts: AzureAccount[];
    private gcpAccounts: GcpAccount[];
    private linodeAccounts: LinodeAccount[];
    //
    currentSection = "info";
    architecture: "arm" | "x86" = "arm";
    userData?: string;

    tagsControl = new UntypedFormControl([], [Validators.required]);

    customImageControl = new FormControl<number>(this.defaultCustomImage.id);
    customImages$ = this.customImageService.customImages.pipe(
        map(customImages => {
            customImages.unshift(this.defaultCustomImage);
            return customImages;
        })
    );

    async prepForm() {
        if (this.action && this.cluster) {
            this.tagsControl.setValue(this.cluster.resourceTags);
            if (this.cluster.customImage) this.customImageControl.setValue(this.cluster.customImage.id);
            if (this.action === "edit") this.isEdit = true;

            //
            if (_.isString(this.cluster.subnet) && this.cluster.scaling === "AWS") {
                this.clusterSubnet = this.cluster.subnet.split(",").map(s => {
                    return s.trim();
                });
                this.clusterSecurityGroup = this.cluster.security_group.split(",").map(s => {
                    return s.trim();
                });
            } else {
                this.clusterSubnet = this.cluster.subnet;

                if (this.cluster.scaling === "Azure") this.clusterSecurityGroup = this.cluster.security_group || "-1";
                else this.clusterSecurityGroup = this.cluster.security_group;
            }

            if (_.isString(this.cluster.dpdk_subnet) && this.cluster.scaling === "AWS") {
                this.dpdkClusterSubnet = this.cluster.dpdk_subnet.split(",").map(s => {
                    return s.trim();
                });
                this.dpdkClusterSecurityGroup = (this.cluster.dpdk_security_groups ?? "").split(",").map(s => {
                    return s.trim();
                });
            } else {
                this.dpdkClusterSubnet = this.cluster.dpdk_subnet;
                this.dpdkClusterSecurityGroup = this.cluster.dpdk_security_groups;
            }

            if (_.isString(this.cluster.zones)) {
                this.gcpZones = this.cluster.zones.split(",").map(s => {
                    return s.trim();
                });
            }
            if (!this.cluster.gcp_gpu_type) {
                this.cluster.gcp_gpu_type = "none";
            }

            if (this.cluster.scaling === "Linode") {
                this.clusterSecurityGroup = +this.cluster.security_group;
            }

            if (this.clusterSecurityGroup === "") this.clusterSecurityGroup = null;
            if (this.dpdkClusterSecurityGroup === "") this.dpdkClusterSecurityGroup = null;

            if (
                _.isString(this.cluster.user_data) &&
                this.cluster.user_data &&
                ["AWS", "Linode"].includes(this.cluster.scaling)
            ) {
                this.userData = this.fromBase64(this.cluster.user_data);
            }

            this.showDeprecatedInstanceTypes = AWSInstanceDeprecatedx86MachineTypes.includes(
                this.cluster.instance_type
            );
            this.dnsPrefix = this.cluster.dns_prefix;

            this.architecture = AWSInstanceArmMachineTypes.includes(this.cluster.instance_type) ? "arm" : "x86";
        }

        if (!this.cluster && !this.isEdit) {
            this.cluster = new Cluster();
            this.cluster.name = null;
            this.cluster.api_user = "admin";
            this.cluster.load_balance = 0;
            this.cluster.http_streaming_port = 7777;
            this.cluster.root_device_size = 32;
            this.cluster.auth_mode = "zen";
            this.cluster.realtime_bx_priority = 0;
            this.rtmpOn = false;
            this.cluster.rtmp_input_port = null;
            this.cluster.inputs_billing_code = null;
            this.cluster.inputs_billing_password = null;
            this.cluster.outputs_billing_code = null;
            this.cluster.outputs_billing_password = null;
            this.cluster.auto_agentz = false;
            this.cluster.detailed_monitoring = false;
            this.cluster.require_eip = false;
            this.cluster.user_data = "";
            this.cluster.alt_cluster_id = null;
            this.cluster.dtls = 0;
            this.cluster.dpdk = false;
            this.cluster.dpdk_elastic_ips = "";
            this.cluster.dpdk_security_groups = null;

            //
            this.scalingAccountSelector = "manual-0";
        }

        if (this.cluster) {
            this.rtmpOn = this.cluster.rtmp_input_port > 0;
        }

        if (this.isEdit) {
            if (this.cluster.azure_account_id || this.cluster.gcp_account_id || this.cluster.linode_account_id)
                this.architecture = "x86";

            if (this.nvidiaOptionsLegacy.find(no => no.id === this.cluster.gcp_gpu_type) != null) {
                this.nvidiaOptions = [...this.nvidiaOptionsLegacy, ...this.nvidiaOptions];
            }
        }

        // Set Title
        this.titleService.setTitle("BROADCASTER_CLUSTER", this.action, this.cluster);
    }

    generateStrongPassword() {
        this.cluster.auth_param = this.sharedService.generateStrongPassword();
    }

    async loadScalingAccounts() {
        this.loadingScalingAccounts = true;

        const bxVersions = await this.getBxVersions();
        const awsAccounts = await this.getAWSAccounts();
        const azureAccounts = await this.getAzureAccounts();
        const gcpAccounts = await this.getGCPAccounts();
        const linodeAccounts = await this.getLinodeAccounts();

        await Promise.all([bxVersions, awsAccounts, azureAccounts, gcpAccounts]);

        for (const awsAccount of awsAccounts) {
            this.scalingAccounts.push({
                name: awsAccount.name,
                id: "aws-" + awsAccount.id,
                groupName: "AWS"
            });
            if (this.isEdit && this.cluster.aws_account_id === awsAccount.id) {
                this.scalingAccount = Object.assign({ type: "aws" }, awsAccount);
            }
        }

        for (const azureAccount of azureAccounts) {
            this.scalingAccounts.push({
                name: azureAccount.name,
                id: "azure-" + azureAccount.id,
                groupName: "Azure"
            });
            if (this.isEdit && this.cluster.azure_account_id === azureAccount.id) {
                this.scalingAccount = Object.assign({ type: "azure" }, azureAccount);
            }
        }

        for (const gcpAccount of gcpAccounts) {
            this.scalingAccounts.push({
                name: gcpAccount.name,
                id: "gcp-" + gcpAccount.id,
                groupName: "GCP"
            });
            if (this.isEdit && this.cluster.gcp_account_id === gcpAccount.id) {
                this.scalingAccount = Object.assign({ type: "gcp" }, gcpAccount);
            }
        }

        for (const linodeAccount of linodeAccounts) {
            this.scalingAccounts.push({
                name: linodeAccount.name,
                id: "linode-" + linodeAccount.id,
                groupName: "LINODE"
            });
            if (this.isEdit && this.cluster.linode_account_id === linodeAccount.id) {
                this.scalingAccount = Object.assign({ type: "linode" }, linodeAccount);
            }
        }

        if (!this.isEdit && this.scalingAccounts.length === 1) this.scalingAccount = this.scalingAccountManual;

        if (
            this.isEdit &&
            this.cluster._frontData &&
            !this.cluster._frontData.is_aws &&
            !this.cluster._frontData.is_azure &&
            !this.cluster._frontData.is_linode &&
            !this.cluster._frontData.is_gcp
        )
            this.scalingAccount = this.scalingAccountManual;

        if (!this.isEdit) {
            if (bxVersions && bxVersions.zcpVersions && bxVersions.zcpVersions.length > 0)
                this.cluster.zcp_version_id = _.first(bxVersions.zcpVersions).file_id;
        } else {
            this.scalingRegion = this.cluster.region;
        }

        if (this.isEdit && bxVersions && this.cluster.zcp_version_id == null && this.cluster.version_id != null) {
            // fake the existing ZM version as a ZCP version so the selection is not blank when editing an existing cluster with a ZM version
            const versionName =
                this.bxVersions.zmVersions.find(v => v.id === this.cluster.version_id)?.name ||
                this.bxVersions.zcpVersions[0].version;
            bxVersions.zcpVersions.push({
                file_id: this.cluster.version_id,
                version: versionName,
                retired: true,
                platform_name: "Linux (X86 64-bit)",
                platform_code: "linux64",
                is_private: 0
            });
            bxVersions.zcpVersions = _.sortBy(bxVersions.zcpVersions, v => {
                return v.version;
            }).reverse();
            this.cluster.zcp_version_id = this.cluster.version_id;
        }

        // fake the existing ZM ZCP version as a ZCP version if the version was removed from ZCP
        if (
            this.isEdit &&
            bxVersions &&
            this.cluster.zcp_version_id != null &&
            bxVersions.zcpVersions.find(v => v.file_id === this.cluster.zcp_version_id) == null
        ) {
            bxVersions.zcpVersions.push({
                file_id: this.cluster.zcp_version_id,
                version: this.cluster.zcp_version_name,
                retired: true,
                platform_name: "Linux (X86 64-bit)",
                platform_code: "linux64",
                is_private: 0
            });
            bxVersions.zcpVersions = _.sortBy(bxVersions.zcpVersions, v => {
                return v.version;
            }).reverse();
        }

        this.scalingAccounts = [...this.scalingAccounts];
        this.loadingScalingAccounts = false;
    }

    async ngOnInit() {
        this.customImageService.refreshCustomImages();
        const params = this.route.snapshot.params;
        this.clusterId = urlBuilder.decode(params.clusterId);
        this.action = params.action;
        if (this.clusterId) {
            this.cluster = _.cloneDeep(this.cs.getCachedCluster(null, this.clusterId));
            this.existingCluster = _.cloneDeep(this.cluster);
        }
        this.loading = false;

        this.customImageControl.valueChanges.subscribe(async value => {
            const selectedCustomImage = (await firstValueFrom(this.customImages$)).find(image => image.id === value);
            if (selectedCustomImage) {
                this.architecture = selectedCustomImage.architecture;
                this.updateVersionAndInstanceTypesOptions();
            }
        });

        // Check if found in cache
        if (this.sharedService.isEmptyObject(this.cluster)) {
            await firstValueFrom(this.cs.refreshClusters(true));
            this.cluster = Object.assign({}, this.cs.getCachedCluster(null, this.clusterId));

            this.cluster = await firstValueFrom(this.cs.refreshCluster(this.cluster.id, true));
            this.existingCluster = _.cloneDeep(this.cluster);
        } else {
            await firstValueFrom(this.cs.refreshClusters());
        }

        this.prepForm();

        this.clustersSubscription = this.cs.clusters.subscribe((clusters: Cluster[]) => {
            this.clusterDNSPrefixes = clusters.map(c => c.dns_prefix);
            this.clusterNames = clusters.map(c => c.name);
            this.clusterDNSPrefixes.push("feeder");
            if (this.isEdit) {
                this.clusterDNSPrefixes = _.without(this.clusterDNSPrefixes, this.cluster.dns_prefix);
                this.clusterNames = _.without(this.clusterNames, this.cluster.name);
            }
        });

        this.applicationHost = await firstValueFrom(this.us.user.pipe(map(user => user.application_host)));
        await this.loadScalingAccounts();

        if (this.isEdit) {
            await this.scalingRegionChanged();
            await this.vpcChanged();
            await this.subnetChanged();
        }

        this.init = false;
    }

    ngOnDestroy() {
        this.clustersSubscription?.unsubscribe();
    }

    async onSubmit() {
        if (
            this.cluster.auth_mode === "ffa" &&
            !this.cluster.allow_unmanaged_outputs &&
            !this.cluster.allow_unmanaged_inputs
        )
            return;
        if (this.customError) return;

        if (this.isAWSScaling() || this.isAzureScaling() || this.isGCPScaling())
            if (this.isEdit) {
                if (this.cluster.broadcasters?.length === 0 && (!this.cluster.api_password || !this.cluster.api_user))
                    return;
            } else {
                if (!this.cluster.api_password || !this.cluster.api_user) return;
            }

        if (
            this.isAWSScaling() &&
            (this.hasMixedSubnets || this.hasDPDKMixedSubnets || this.unmatchedDPDKSubnetsPresent)
        ) {
            return;
        }

        this.saving = true;

        let model = {
            name: this.cluster.name,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.cluster.alertingProfile.id,
            http_streaming_port: this.cluster.http_streaming_port,
            auth_mode: this.cluster.auth_mode,
            auth_param: this.cluster.auth_param,
            allow_unmanaged_inputs: this.cluster.allow_unmanaged_inputs,
            allow_unmanaged_outputs: this.cluster.allow_unmanaged_outputs,
            dtls: this.cluster.dtls,
            allow_ignore_dtls_cert: this.cluster.allow_ignore_dtls_cert,
            load_balance: this.cluster.load_balance,
            bx_input_bw_limit: this.cluster.bx_input_bw_limit,
            bx_output_bw_limit: this.cluster.bx_output_bw_limit,
            transcode_mem_limit: this.cluster.transcode_mem_limit,
            transcode_cpu_limit: this.cluster.transcode_cpu_limit,
            transcode_hdd_limit: this.cluster.transcode_hdd_limit,
            transcode_gpu_limit: this.cluster.transcode_gpu_limit,
            transcode_gpu_dec_limit: this.cluster.transcode_gpu_dec_limit,
            transcode_gpu_enc_limit: this.cluster.transcode_gpu_enc_limit,
            transcode_gpu_mem_limit: this.cluster.transcode_gpu_mem_limit,
            configure_scte_reports: this.cluster.configure_scte_reports,
            realtime_bx_priority: this.cluster.realtime_bx_priority,
            rtmp_input_port: this.rtmpOn ? this.cluster.rtmp_input_port ?? 1935 : null,
            inputs_billing_code: this.cluster.inputs_billing_code,
            inputs_billing_password: this.cluster.inputs_billing_password,
            outputs_billing_code: this.cluster.outputs_billing_code,
            outputs_billing_password: this.cluster.outputs_billing_password,
            termination_protection: this.cluster.termination_protection,
            auto_agentz: this.cluster.auto_agentz,
            external_auth_url:
                this.cluster.external_auth_url && this.cluster.external_auth_url.length > 0
                    ? this.cluster.external_auth_url
                    : null,
            external_auth_type:
                this.cluster.external_auth_type && this.cluster.external_auth_type.length > 0
                    ? this.cluster.external_auth_type
                    : null,
            external_auth_credentials:
                this.cluster.external_auth_credentials && this.cluster.external_auth_credentials.length > 0
                    ? this.cluster.external_auth_credentials
                    : null,
            detailed_monitoring: !!this.cluster.detailed_monitoring,
            require_eip: !!this.cluster.require_eip,
            alt_cluster_id: this.cluster.alt_cluster_id,
            dpdk: this.cluster.dpdk
        };

        if (!this.isEdit) {
            model = _.extend(model, {
                dns_prefix: this.cluster.dns_prefix
            });
        } else {
            if (
                this.existingCluster.version_id != null &&
                this.cluster.zcp_version_id === this.existingCluster.version_id
            )
                this.cluster.zcp_version_id = null;
            else this.cluster.version_id = null;
        }

        if (this.isAWSScaling()) {
            model = _.extend(model, {
                activation_key: this.cluster.activation_key,
                version_id: this.cluster.version_id,
                zcp_version_id: this.cluster.zcp_version_id,
                zcp_version_name:
                    this.cluster.zcp_version_id != null
                        ? this.bxVersions.zcpVersions.find(v => v.file_id === this.cluster.zcp_version_id).version
                        : null,
                eips: this.cluster.eips,
                instance_type: this.cluster.instance_type,
                root_device_size: this.cluster.root_device_size,
                security_group: Array.isArray(this.clusterSecurityGroup)
                    ? this.clusterSecurityGroup.join(", ")
                    : this.clusterSecurityGroup,
                dpdk_security_groups: Array.isArray(this.dpdkClusterSecurityGroup)
                    ? this.dpdkClusterSecurityGroup.join(", ")
                    : this.dpdkClusterSecurityGroup,
                dpdk_elastic_ips: this.cluster.dpdk_elastic_ips,
                subnet: Array.isArray(this.clusterSubnet) ? this.clusterSubnet.join(", ") : this.cluster.subnet,
                user_data: this.userData ? this.toBase64(this.userData) : null,
                ami_id: this.customImageControl.value || null,
                api_password: this.cluster.api_password,
                api_user: this.cluster.api_user,
                dpdk_subnet: Array.isArray(this.dpdkClusterSubnet)
                    ? this.dpdkClusterSubnet.join(", ")
                    : this.cluster.dpdk_subnet
            });

            if (!this.isEdit) {
                model = _.extend(model, {
                    aws_account_id: this.scalingAccount.type === "aws" ? this.scalingAccount.id : undefined,
                    azure_account_id: undefined,
                    gcp_account_id: undefined,
                    linode_account_id: undefined,
                    vpc: this.cluster.vpc,
                    region: this.scalingRegion,
                    key_pair: this.key
                });
            }
        }

        if (this.isAzureScaling()) {
            model = _.extend(model, {
                activation_key: this.cluster.activation_key,
                version_id: this.cluster.version_id,
                zcp_version_id: this.cluster.zcp_version_id,
                zcp_version_name:
                    this.cluster.zcp_version_id != null
                        ? this.bxVersions.zcpVersions.find(v => v.file_id === this.cluster.zcp_version_id).version
                        : null,
                eips: this.cluster.eips,
                instance_type: this.cluster.instance_type,
                root_device_size: undefined,
                security_group: this.clusterSecurityGroup === "-1" ? null : this.clusterSecurityGroup,
                subnet: this.clusterSubnet,
                api_password: this.cluster.api_password,
                api_user: this.cluster.api_user
            });

            if (!this.isEdit) {
                model = _.extend(model, {
                    aws_account_id: undefined,
                    azure_account_id: this.scalingAccount.type === "azure" ? this.scalingAccount.id : undefined,
                    gcp_account_id: undefined,
                    linode_account_id: undefined,
                    vpc: this.cluster.vpc,
                    region: this.scalingRegion,
                    key_pair: undefined
                });
            }
        }

        if (this.isGCPScaling()) {
            model = _.extend(model, {
                activation_key: this.cluster.activation_key,
                version_id: this.cluster.version_id,
                zcp_version_id: this.cluster.zcp_version_id,
                zcp_version_name:
                    this.cluster.zcp_version_id != null
                        ? this.bxVersions.zcpVersions.find(v => v.file_id === this.cluster.zcp_version_id).version
                        : null,
                eips: "",
                instance_type: this.cluster.instance_type,
                gcp_gpu_type:
                    !this.cluster.gcp_gpu_type || this.cluster.gcp_gpu_type === "none"
                        ? null
                        : this.cluster.gcp_gpu_type,
                root_device_size: this.cluster.root_device_size,
                security_group: "",
                subnet: this.clusterSubnet,
                zones: this.gcpZones.join(","),
                api_password: this.cluster.api_password,
                api_user: this.cluster.api_user
            });

            if (!this.isEdit) {
                model = _.extend(model, {
                    aws_account_id: undefined,
                    azure_account_id: undefined,
                    gcp_account_id: this.scalingAccount.type === "gcp" ? this.scalingAccount.id : undefined,
                    linode_account_id: undefined,
                    vpc: this.cluster.vpc,
                    region: this.scalingRegion,
                    key_pair: undefined
                });
            }
        }

        if (this.isLinodeScaling()) {
            model = _.extend(model, {
                activation_key: this.cluster.activation_key,
                version_id: this.cluster.version_id,
                zcp_version_id: this.cluster.zcp_version_id,
                zcp_version_name:
                    this.cluster.zcp_version_id != null
                        ? this.bxVersions.zcpVersions.find(v => v.file_id === this.cluster.zcp_version_id).version
                        : null,
                eips: "",
                instance_type: this.cluster.instance_type,
                root_device_size: this.cluster.root_device_size,
                security_group: this.clusterSecurityGroup === "-1" ? null : this.clusterSecurityGroup,
                linode_image_id: this.cluster.linode_image_id,
                linode_authorized_users: this.cluster.linode_authorized_users,
                user_data: this.userData ? this.toBase64(this.userData) : null,
                subnet: this.clusterSubnet,
                zones: this.gcpZones.join(","),
                api_password: this.cluster.api_password,
                api_user: this.cluster.api_user
            });

            if (!this.isEdit) {
                model = _.extend(model, {
                    aws_account_id: undefined,
                    azure_account_id: undefined,
                    gcp_account_id: undefined,
                    linode_account_id: this.scalingAccount.type === "linode" ? this.scalingAccount.id : undefined,
                    vpc: this.cluster.vpc,
                    region: this.scalingRegion,
                    key_pair: undefined
                });
            }
        }

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingCluster, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedCluster = await this.cs.updateCluster(this.cluster, {
                    ...changedData,
                    restart_confirmed: true
                });
                if (updatedCluster) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update cluster", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularClusterUrl(this.cluster.id, this.cluster.name));
                } else {
                    this.saving = false;
                }
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularClusterUrl(this.cluster.id, this.cluster.name));
            }
        } else {
            const result = await this.cs.addCluster(model);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create broadcaster cluster");
                this.router.navigate(urlBuilder.getRegularClusterUrl(result.id, this.cluster.name));
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit) this.router.navigate(urlBuilder.getRegularClusterUrl(this.clusterId, this.cluster.name));
        else this.router.navigate([Constants.urls.clusters]);
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    async getBxVersions() {
        try {
            const result = await this.cs.getBxVersions();

            result.zmVersions = _.sortBy(result.zmVersions, v => {
                return v.name;
            }).reverse();
            result.zcpVersions = _.sortBy(result.zcpVersions, v => {
                return v.version;
            }).reverse();

            this.bxVersions = result;
            return this.bxVersions;
        } catch (err) {
            this.bxVersions = null;
            this.customError = err.error.error;
            return this.bxVersions;
        }
    }

    async getAWSAccounts() {
        const result = await this.cs.getAWSAccounts();
        if (result) {
            this.awsAccounts = result;
            return this.awsAccounts;
        } else {
            this.awsAccounts = [];
            return this.awsAccounts;
        }
    }

    async getAzureAccounts() {
        const result = await this.cs.getAzureAccounts();
        if (result) {
            this.azureAccounts = result;
            return this.azureAccounts;
        } else {
            this.azureAccounts = [];
            return this.azureAccounts;
        }
    }

    async getGCPAccounts() {
        const result = await this.cs.getGCPAccounts();
        if (result) {
            this.gcpAccounts = result;
            return this.gcpAccounts;
        } else {
            this.gcpAccounts = [];
            return this.gcpAccounts;
        }
    }

    async getLinodeAccounts() {
        const result = await firstValueFrom(this.linodeService.refreshLinodeAccounts());
        if (result) {
            this.linodeAccounts = result;
            return this.linodeAccounts;
        } else {
            this.linodeAccounts = [];
            return this.linodeAccounts;
        }
    }

    isAWSScaling() {
        return this.scalingAccount && this.scalingAccount.type === "aws";
    }

    isAzureScaling() {
        return this.scalingAccount && this.scalingAccount.type === "azure";
    }

    isGCPScaling() {
        return this.scalingAccount && this.scalingAccount.type === "gcp";
    }

    isLinodeScaling() {
        return this.scalingAccount && this.scalingAccount.type === "linode";
    }

    scalingAccountChanged() {
        if (this.isEdit) return;
        if (!this.scalingAccountSelector) return;

        const scalingAccountInfo = this.scalingAccountSelector.split("-");
        this.scalingAccount = {
            type: scalingAccountInfo[0],
            id: parseInt(scalingAccountInfo[1], 10),
            name: null
        };

        let account = null;
        if (this.isAWSScaling()) {
            account = _.find(this.awsAccounts, a => {
                return a.id === this.scalingAccount.id;
            });
            this.architecture = "arm";
        } else if (this.isAzureScaling()) {
            account = _.find(this.azureAccounts, a => {
                return a.id === this.scalingAccount.id;
            });
            this.architecture = "x86";
        } else if (this.isGCPScaling()) {
            account = _.find(this.gcpAccounts, a => {
                return a.id === this.scalingAccount.id;
            });
            this.architecture = "x86";
        } else if (this.isLinodeScaling()) {
            account = _.find(this.linodeAccounts, a => {
                return a.id === this.scalingAccount.id;
            });
            this.architecture = "x86";
        }

        if (account) {
            this.scalingAccount.name = account.name;
            this.getAutoScalingRegions();
            this.cluster.dtls = 1;
        } else {
            this.scalingAccount.name = "Manual";
        }

        this.scalingRegion = null;
        this.cluster.remote_access_key_id = null;
        this.cluster.vpc = null;
        this.cluster.security_group = null;
        this.cluster.instance_type = null;

        this.clusterSecurityGroup = null;
        this.dpdkClusterSecurityGroup = null;

        this.updateVersionAndInstanceTypesOptions();
    }

    async getAutoScalingRegions() {
        this.autoscalingRegions = [];
        this.loadingScalingRegions = true;
        if (!this.scalingAccount.id) {
            this.loadingScalingRegions = false;
            return;
        }

        if (this.isAWSScaling()) {
            try {
                const result = await this.cs.getAWSRegions(this.scalingAccount.id);

                const awsRegions = [];
                Object.entries(result).forEach(([key, value]) => {
                    awsRegions.push({ id: key, name: value });
                });
                this.autoscalingRegions = awsRegions;
                this.loadingScalingRegions = false;
            } catch (err) {
                this.loadingScalingRegions = false;
                this.customError = err.error?.error ?? "Unexpected Error";
            }
        } else if (this.isAzureScaling()) {
            try {
                const result = await this.cs.getAzureRegions(this.scalingAccount.id);
                const azureRegions = [];
                for (const key of Object.keys(result)) {
                    let prettyName = key;
                    const azureRegion = this.fp.transform(this.azureRegions, "value", key);
                    if (azureRegion && azureRegion.length > 0) prettyName = azureRegion[0].name;
                    azureRegions.push({ id: key, name: prettyName });
                }
                this.autoscalingRegions = azureRegions;
                this.loadingScalingRegions = false;
            } catch (err) {
                this.regionsLoadError = err.error?.error ?? "Unexpected Error";
                this.loadingScalingRegions = false;
                this.customError = err.error?.error ?? "Unexpected Error";
            }
        } else if (this.isGCPScaling()) {
            try {
                const regions = await this.cs.getGCPRegions(this.scalingAccount.id);

                const gcpRegions = [];
                for (const region of regions) {
                    let prettyName = region;
                    const gcpRegion = this.fp.transform(this.gcpRegions, "value", region);
                    if (gcpRegion && gcpRegion.length > 0) prettyName = gcpRegion[0].name;
                    gcpRegions.push({ id: region, name: prettyName });
                }
                this.autoscalingRegions = gcpRegions;
                this.loadingScalingRegions = false;
            } catch (err) {
                this.loadingScalingRegions = false;
                this.customError = err.error?.error ?? "Unexpected Error";
            }
        } else if (this.isLinodeScaling()) {
            try {
                const result = await firstValueFrom(this.linodeService.getAccount(this.scalingAccount.id));
                this.autoscalingRegions = result.regions ?? [];
                this.securityGroupOptions = result.firewalls ?? [];
                this.linodeImages = result.images ?? [];

                this.loadingScalingRegions = false;
            } catch (err) {
                this.loadingScalingRegions = false;
                this.customError = err.error?.error ?? "Unexpected Error";
            }
        } else {
            this.loadingScalingRegions = false;
            return;
        }
    }

    async scalingRegionChanged() {
        this.loadingRegionData = true;

        if (!this.scalingAccount.id && !this.scalingRegion) {
            this.loadingRegionData = false;
            return;
        }

        if (!this.isEdit) {
            this.cluster.vpc = null;
            this.cluster.subnet = null;
            this.clusterSubnet = null;
            this.key = null;
        }

        if (this.isAWSScaling() && this.scalingRegion) {
            this.customImages$ = this.customImageService.customImages.pipe(
                map(customImages => {
                    customImages = customImages.filter(ami => ami.region === this.scalingRegion);
                    customImages.unshift(this.defaultCustomImage);
                    return customImages;
                })
            );
            const customImages = await firstValueFrom(this.customImages$);
            if (!customImages.find(ami => ami.id === this.customImageControl.value))
                this.customImageControl.setValue(this.defaultCustomImage.id);
            try {
                const result = await this.cs.getAWSAutoScalingRegionOptions(this.scalingAccount.id, this.scalingRegion);

                this.vpcList = result.vpcs;

                if (this.isEdit) {
                    this.securityGroupOptions = this.vpcList[this.cluster.vpc].security_groups;
                    if (this.cluster.security_group != null)
                        this.clusterSecurityGroup = this.cluster.security_group.split(",").map(sg => {
                            return sg.trim();
                        });
                    else {
                        this.clusterSecurityGroup = [];
                    }

                    if (this.cluster.dpdk_security_groups != null) {
                        this.dpdkClusterSecurityGroup = this.cluster.dpdk_security_groups.split(",").map(sg => {
                            return sg.trim();
                        });
                    } else {
                        this.dpdkClusterSecurityGroup = [];
                    }
                } else
                    this.vpcOptions = _.map(result.vpcs, (vpc, id) => {
                        return { id, name: vpc.name };
                    });

                this.keyList = _.map(result.keys, k => {
                    return { id: k, name: k };
                });

                this.pricing = result.pricing;
            } catch (err) {
                this.securityGroupOptions = [];
                this.clusterSecurityGroup = [];
                this.dpdkClusterSecurityGroup = [];
                this.keyList = [];
                this.instanceTypesWithPricing = [];
                this.clusterSubnet = [];
                this.customError = err.error.error;
            }
        } else if (this.isAzureScaling() && this.scalingRegion) {
            this.architecture = "x86";
            try {
                const result = await this.cs.getAzureAutoScalingRegionOptions(
                    this.scalingAccount.id,
                    this.scalingRegion
                );

                this.vpcOptions = result.networks;
                this.securityGroupOptions = result.securityGroups;
                this.azureVMSizes = result.virtualMachineSizes;
                this.securityGroupOptions.unshift({ id: "-1", name: "Use Subnet Network Security Group" });
            } catch (err) {
                this.vpcOptions = [];
                this.securityGroupOptions = [];
                this.azureVMSizes = [];
                this.customError = err.error.error;
            }
        } else if (this.isGCPScaling() && this.scalingRegion) {
            this.architecture = "x86";
            try {
                const result = await this.cs.getGCPAutoScalingRegionOptions(this.scalingAccount.id, this.scalingRegion);

                this.subnetOptions = result.networks;
                this.gcpVMSizes = result.machines;
                this.zonesOptions = result.zones;
                this.gcpZones = _.map(this.zonesOptions, z => {
                    return z.id;
                });
            } catch (err) {
                this.subnetOptions = [];
                this.gcpVMSizes = [];
                this.zonesOptions = [];
                this.gcpZones = [];
                this.customError = err.error.error;
            }
        } else if (this.isLinodeScaling() && this.isEdit) {
            try {
                const result = await firstValueFrom(this.linodeService.getAccount(this.scalingAccount.id));
                this.securityGroupOptions = result.firewalls ?? [];
                this.linodeImages = result.images ?? [];
            } catch (err) {
                this.securityGroupOptions = [];
            }
        }

        this.updateVersionAndInstanceTypesOptions();
        this.loadingRegionData = false;
    }

    async vpcChanged() {
        if (this.isAWSScaling()) {
            if (this.cluster && this.cluster.vpc && this.vpcList && this.vpcList[this.cluster.vpc]) {
                this.subnetOptions = _.map(this.vpcList[this.cluster.vpc].subnets, (v, k) => {
                    return { id: k, name: v };
                });
                this.securityGroupOptions = this.vpcList[this.cluster.vpc].security_groups;
            }
        } else if (this.isAzureScaling()) {
            if (this.cluster && this.cluster.vpc && this.vpcOptions.length > 0) {
                const matchingVpc = this.vpcOptions.find(vpc => vpc.id === (this.cluster && this.cluster.vpc));
                this.subnetOptions = matchingVpc?.subnets || [];
            } else this.subnetOptions = [];
        }
    }

    async subnetChanged(ngSelect?: NgModel) {
        if (this.isAWSScaling()) {
            this.hasMixedSubnets = false;
            this.isSubnetTypeWavelength = false;
            const selectedSubnets = this.subnetOptions.filter(
                subnet => (this.clusterSubnet ?? []).indexOf(subnet.id) !== -1
            );
            const dpdkSelectedSubnets = this.subnetOptions.filter(
                subnet => (this.dpdkClusterSubnet ?? []).indexOf(subnet.id) !== -1
            );

            if (selectedSubnets.length < 1) return;

            // check if wavelength and non-wavelength subnets are mixed
            const subnetType = this.isSubnetWavelength(selectedSubnets[0].name);
            const dpdkSubnetType =
                dpdkSelectedSubnets.length > 0 ? this.isSubnetWavelength(dpdkSelectedSubnets[0].name) : false;
            this.isSubnetTypeWavelength = dpdkSubnetType || subnetType;

            this.hasMixedSubnets = !selectedSubnets.every(sn => this.isSubnetWavelength(sn.name) === subnetType);

            if (this.hasMixedSubnets) {
                ngSelect.control.setErrors({ hasMixedSubnets: true });
            } else if (subnetType) {
                this.architecture = "x86";
                this.showDeprecatedInstanceTypes = false;
                this.cluster.instance_type = null;
                this.updateVersionAndInstanceTypesOptions();
            }

            this.checkDPDKSubnetsHaveUnmatchedZones();
            if (this.unmatchedDPDKSubnetsPresent) {
                this.dpdkSubnetSelectElement?.control.setErrors({ unmatchedDPDKSubnetsPresent: true });
            }
        }
    }

    async dpdkSubnetChanged(ngSelect?: NgModel) {
        if (this.isAWSScaling()) {
            this.hasDPDKMixedSubnets = false;
            const selectedSubnets = this.subnetOptions.filter(
                subnet => (this.clusterSubnet ?? []).indexOf(subnet.id) !== -1
            );
            const dpdkSelectedSubnets = this.subnetOptions.filter(
                subnet => (this.dpdkClusterSubnet ?? []).indexOf(subnet.id) !== -1
            );

            if (dpdkSelectedSubnets.length < 1) return;

            // check if wavelength and non-wavelength subnets are mixed
            const subnetType = selectedSubnets.length > 0 ? this.isSubnetWavelength(selectedSubnets[0].name) : false;
            const dpdkSubnetType = this.isSubnetWavelength(dpdkSelectedSubnets[0].name);
            this.isSubnetTypeWavelength = dpdkSubnetType || subnetType;

            this.hasDPDKMixedSubnets = !dpdkSelectedSubnets.every(
                sn => this.isSubnetWavelength(sn.name) === dpdkSubnetType
            );

            const errors: { hasDPDKMixedSubnets?: boolean; unmatchedDPDKSubnetsPresent?: boolean } = {};
            if (this.hasDPDKMixedSubnets) errors.hasDPDKMixedSubnets = true;

            if (!this.hasDPDKMixedSubnets && dpdkSubnetType) {
                this.architecture = "x86";
                this.showDeprecatedInstanceTypes = false;
                this.cluster.instance_type = null;
                this.updateVersionAndInstanceTypesOptions();
            }

            this.checkDPDKSubnetsHaveUnmatchedZones();
            if (this.unmatchedDPDKSubnetsPresent) errors.unmatchedDPDKSubnetsPresent = true;
            if (Object.keys(errors).length > 0) ngSelect.control.setErrors(errors);
        }
    }

    private isSubnetWavelength(subnetName: string) {
        return !!aws.wavelengthZoneList.find(id => subnetName.includes(id));
    }

    clusterNameChange() {
        if (this.dnsPrefixPristine) {
            // Use setTimeout instead of old complicated code
            window.setTimeout(() => {
                this.cluster.dns_prefix = this.cluster.name
                    ? this.cluster.name
                          .replace(/[^a-zA-Z0-9]/g, "-")
                          .replace(/^-+/g, "")
                          .toLocaleLowerCase()
                    : "";
            }, 0);
        }
    }

    clusterPrefixTouch() {
        this.dnsPrefixPristine = false;
        return true;
    }

    // Error Code
    infoHasErrors(form: NgForm) {
        if (form.form.controls.name && form.form.controls.name.invalid === true) return true;
        if (form.form.controls.dns_prefix && form.form.controls.dns_prefix.invalid === true) return true;
        return this.tagsControl.invalid;
    }

    settingsHasErrors(form: NgForm) {
        if (form.form.controls.scalingAccount && form.form.controls.scalingAccount.invalid === true) return true;
        if (form.form.controls.region && form.form.controls.region.invalid === true) return true;
        if (form.form.controls.key_pair && form.form.controls.key_pair.invalid === true) return true;
        if (form.form.controls.vpc && form.form.controls.vpc.invalid === true) return true;
        if (form.form.controls.subnets && form.form.controls.subnets.invalid === true) return true;
        if (form.form.controls.security_groups && form.form.controls.security_groups.invalid === true) return true;
        if (form.form.controls.instance_type && form.form.controls.instance_type.invalid === true) return true;
        if (form.form.controls.root_device_size && form.form.controls.root_device_size.invalid === true) return true;
        if (form.form.controls.network_security_group && form.form.controls.network_security_group.invalid === true)
            return true;
        if (form.form.controls.instance_size && form.form.controls.instance_size.invalid === true) return true;
        if (form.form.controls.gcpZones && form.form.controls.gcpZones.invalid === true) return true;
        if (form.form.controls.network && form.form.controls.network.invalid === true) return true;
        if (form.form.controls.machine_type && form.form.controls.machine_type.invalid === true) return true;
        if (form.form.controls.gcp_gpu_type && form.form.controls.gcp_gpu_type.invalid === true) return true;
        if (form.form.controls.activation_key && form.form.controls.activation_key.invalid === true) return true;
        if (form.form.controls.api_user && form.form.controls.api_user.invalid === true) return true;
        if (form.form.controls.api_password && form.form.controls.api_password.invalid === true) return true;
        if (form.form.controls.broadcaster_version && form.form.controls.broadcaster_version.invalid === true)
            return true;
        //
        if (form.form.controls.auth_mode && form.form.controls.auth_mode.invalid === true) return true;
        if (form.form.controls.auth_param && form.form.controls.auth_param.invalid === true) return true;
        if (form.form.controls.dpdk_subnets && form.form.controls.dpdk_subnets.invalid === true) return true;
        if (form.form.controls.dpdk_security_groups && form.form.controls.dpdk_security_groups.invalid === true)
            return true;

        return false;
    }

    advancedHasErrors(form: NgForm) {
        if (form.form.controls.http_streaming_port && form.form.controls.http_streaming_port.invalid === true)
            return true;
        if (form.form.controls.bx_input_bw_limit && form.form.controls.bx_input_bw_limit.invalid === true) return true;
        if (form.form.controls.bx_output_bw_limit && form.form.controls.bx_output_bw_limit.invalid === true)
            return true;
        if (form.form.controls.transcode_cpu_limit && form.form.controls.transcode_cpu_limit.invalid === true)
            return true;
        if (form.form.controls.transcode_mem_limit && form.form.controls.transcode_mem_limit.invalid === true)
            return true;
        if (form.form.controls.transcode_gpu_limit && form.form.controls.transcode_gpu_limit.invalid === true)
            return true;
        if (form.form.controls.transcode_gpu_mem_limit && form.form.controls.transcode_gpu_mem_limit.invalid === true)
            return true;
        if (form.form.controls.transcode_gpu_dec_limit && form.form.controls.transcode_gpu_dec_limit.invalid === true)
            return true;
        if (form.form.controls.transcode_gpu_enc_limit && form.form.controls.transcode_gpu_enc_limit.invalid === true)
            return true;
        return false;
    }

    // Scroll Spy Code
    onSectionChange(section: string) {
        this.currentSection = section;
    }

    scrollTo(section: string) {
        window.setTimeout(() => {
            document.querySelector("#" + section).scrollIntoView({ behavior: "smooth", block: "start" });
        }, 0);
    }

    retiredVersion(id: number): boolean {
        const ver = this.bxVersions.zcpVersions.find(v => v.file_id === id);
        return ver && ver.retired;
    }

    updateVersionAndInstanceTypesOptions() {
        this.updateSupportedVersions();

        if (!this.broadcasterVersions.length) {
            this.cluster.zcp_version_id = undefined;
        } else if (!this.broadcasterVersions?.find(v => v.file_id === this.cluster.zcp_version_id)) {
            this.cluster.zcp_version_id = this.broadcasterVersions[0].file_id;
        }

        if (!this.isAWSScaling()) return;

        const instanceTypes = this.getInstanceTypes();

        this.instanceTypesWithPricing = _.reduce(
            instanceTypes,
            (instanceTypesWithPricing, instanceType) => {
                const awsInstance = this.pricing?.[instanceType];
                if (awsInstance) {
                    const { pricePerUnit, physicalProcessor, vcpu, networkPerformance } = awsInstance;
                    const instancePricing = this.cp.transform(_.flatten(_.toPairs(pricePerUnit))[1]);

                    instanceTypesWithPricing.push({
                        id: instanceType,
                        name: `${instanceType} On Demand Linux ${instancePricing}/hr - ${physicalProcessor} ${vcpu}vCPU ${networkPerformance}`
                    });
                }
                return instanceTypesWithPricing;
            },
            []
        );

        if (
            !this.instanceTypesWithPricing.find(
                instanceTypeOption => instanceTypeOption.id === this.cluster.instance_type
            )
        ) {
            this.cluster.instance_type = this.instanceTypesWithPricing[0]?.id ?? null;
            this.updateSupportedVersions();
        }
    }

    private updateSupportedVersions() {
        this.broadcasterVersions = this.bxVersions.zcpVersions.filter(v => {
            if (this.isAzureScaling()) {
                if (this.cluster.instance_type?.startsWith("Standard_N")) {
                    return v.platform_code === "linux64" && +v.version.slice(0, 2) >= 14;
                } else {
                    return v.platform_code === "linux64";
                }
            } else if (this.isGCPScaling()) {
                return v.platform_code === "linux64";
            } else {
                if (this.cluster.instance_type?.startsWith("g5g"))
                    return v.platform_code === "graviton2" && +v.version.slice(0, 4) >= 16.3;
                else
                    return this.architecture === "arm"
                        ? v.platform_code === "graviton2"
                        : v.platform_code === "linux64";
            }
        });
    }

    private getInstanceTypes() {
        if (this.architecture === "arm") {
            return AWSInstanceArmMachineTypes;
        } else {
            if (this.isSubnetTypeWavelength) return AWSInstanceWavelengthMachineTypes;
            if (!this.showDeprecatedInstanceTypes) return AWSInstancex86MachineTypes;
            else return AWSInstancex86MachineTypes.concat(AWSInstanceDeprecatedx86MachineTypes);
        }
    }

    private toBase64(input: string) {
        return Buffer.from(input).toString("base64");
    }

    private fromBase64(base64_string: string) {
        return Buffer.from(base64_string, "base64").toString("utf-8");
    }

    private availabilityZoneFromSubnetName(subnetName: string): string {
        const elements = subnetName.split("|");
        if (elements.length < 2) return "";
        const zone = elements[elements.length - 1];
        return zone.trim();
    }

    checkDPDKSubnetsHaveUnmatchedZones() {
        if (!this.isAWSScaling()) return;

        const selectedSubnets = this.subnetOptions.filter(
            subnet => (this.clusterSubnet ?? []).indexOf(subnet.id) !== -1
        );
        const dpdkSelectedSubnets = this.subnetOptions.filter(
            subnet => (this.dpdkClusterSubnet ?? []).indexOf(subnet.id) !== -1
        );

        this.unmatchedDPDKSubnetsPresent = !!dpdkSelectedSubnets.find(dpdkSubnet => {
            const dpdkZone = this.availabilityZoneFromSubnetName(dpdkSubnet.name);
            const haveMatch = selectedSubnets.find(subnet => {
                const zone = this.availabilityZoneFromSubnetName(subnet.name);
                return zone === dpdkZone;
            });
            return !haveMatch;
        });
    }
}
