import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { Cluster } from "../../cluster";
import { ClustersService } from "../../clusters.service";
import { ClipboardService } from "ngx-clipboard";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-cluster-details",
    templateUrl: "./cluster-details.component.html"
})
export class ClusterDetailsComponent implements OnChanges {
    @Input() cluster: Cluster;
    @Input() canEdit: boolean;
    @Input() isVip: boolean;
    @Input() scalingAccount: { id: number; name: string; type: string };

    clusters: Cluster[];
    authVisible = false;
    numberOfUsedIps: number;
    numberOfTotalIps: number;
    public get numberOfRemainingIps(): number {
        return this.numberOfTotalIps - this.numberOfUsedIps;
    }

    urls = Constants.urls;

    alternativeCluster: Cluster | undefined;

    constructor(private cs: ClustersService, private cbs: ClipboardService) {
        this.cs.clusters.subscribe(clusters => {
            this.clusters = clusters;
        });
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw.toString());
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.cluster) {
            if (changes.cluster.previousValue && changes.cluster.currentValue) {
                if (changes.cluster.previousValue.id !== changes.cluster.currentValue.id) {
                    this.authVisible = false;
                }
            }
        }

        this.numberOfTotalIps = 0;
        this.numberOfUsedIps = 0;
        if (this.cluster?.broadcasters && this.cluster.eips) {
            const broadcastersOnCluster = this.cluster.broadcasters;
            this.numberOfTotalIps = this.cluster.eips?.split(",").length;
            broadcastersOnCluster.forEach(broadcaster => {
                if (broadcaster.eip_allocation_id !== null) {
                    this.numberOfUsedIps++;
                }
            });
        }

        if (this.cluster.alt_cluster_id) {
            this.alternativeCluster = this.clusters.find(c => c.id === this.cluster.alt_cluster_id);
        } else {
            this.alternativeCluster = undefined;
        }
    }
}
